<template>
  <v-container>
    <result-of-disposal-list/>
  </v-container>
</template>

<script>
import ResultOfDisposalList from "@/components/app_setting/result_of_disposal/ResultOfDisposalList";

export default {
  name: "ExecutionTypePage",
  components: {
    ResultOfDisposalList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Case Filed By'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>